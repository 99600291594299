import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/OpenHAB_Home_Automation/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Node-RED Installation on Windows",
  "path": "/Advanced_User/OpenHAB_Home_Automation/Node-RED_Installation_on_Windows/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Node-RED Installation on Windows' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_Heimautomatisierung/Node-RED_Installation_unter_Windows/' locationFR='/fr/Advanced_User/OpenHAB_Home_Automation/Node-RED_Installation_on_Windows/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "node-red-installation-on-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-installation-on-windows",
        "aria-label": "node red installation on windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Installation on Windows`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#nodeRED-on-Windows"
        }}>{`nodeRED on Windows`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Installing-Nodejs"
            }}>{`Installing Node.js`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Installing-nodeRED"
            }}>{`Installing nodeRED`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Run-nodeRED"
            }}>{`Run nodeRED`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#Configuring-nodeRed"
        }}>{`Configuring nodeRed`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#Create-a-Static-directory"
            }}>{`Create a Static directory`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "nodered-on-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nodered-on-windows",
        "aria-label": "nodered on windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`nodeRED on Windows`}</h2>
    <p>{`Setting up `}<a parentName="p" {...{
        "href": "https://nodered.org"
      }}>{`nodeRED`}</a>{` in a Microsoft Windows environment. The instructions are specific to Windows 10 but should also work for Windows 7 and Windows Server from 2008R2.`}</p>
    <h3 {...{
      "id": "installing-nodejs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installing-nodejs",
        "aria-label": "installing nodejs permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing Node.js`}</h3>
    <p>{`Download the latest version of Node.js from the official `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`Node.js home`}</a>{` page. Run the downloaded MSI file. Installing Node.js requires local administrator rights. Once installed, open a command prompt and run the following command to ensure Node.js and npm are installed correctly:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`node -v && npm -v`}</code></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b746c7e56165255f6c01f074fb5eb58/e9beb/nodeRED_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "11.304347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOElEQVQI12NQMfGEIAUDV2Qkr++iYuKpbOQur++KJqVg4Kpo6KZo6MagZxeibu6DqRmsH4s2ZAQArtsXy3B7IqMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b746c7e56165255f6c01f074fb5eb58/e4706/nodeRED_01.avif 230w", "/en/static/0b746c7e56165255f6c01f074fb5eb58/d1af7/nodeRED_01.avif 460w", "/en/static/0b746c7e56165255f6c01f074fb5eb58/b6582/nodeRED_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b746c7e56165255f6c01f074fb5eb58/a0b58/nodeRED_01.webp 230w", "/en/static/0b746c7e56165255f6c01f074fb5eb58/bc10c/nodeRED_01.webp 460w", "/en/static/0b746c7e56165255f6c01f074fb5eb58/87ca7/nodeRED_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b746c7e56165255f6c01f074fb5eb58/81c8e/nodeRED_01.png 230w", "/en/static/0b746c7e56165255f6c01f074fb5eb58/08a84/nodeRED_01.png 460w", "/en/static/0b746c7e56165255f6c01f074fb5eb58/e9beb/nodeRED_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b746c7e56165255f6c01f074fb5eb58/e9beb/nodeRED_01.png",
              "alt": "nodeRED",
              "title": "nodeRED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "installing-nodered",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installing-nodered",
        "aria-label": "installing nodered permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing nodeRED`}</h3>
    <p>{`Installing nodeRED as a global module adds the command node-red to your system path. Execute the following at the command prompt:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install -g --unsafe-perm node-red`}</code></p>
    <h3 {...{
      "id": "run-nodered",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#run-nodered",
        "aria-label": "run nodered permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run nodeRED`}</h3>
    <p>{`Once installed, the simple way to run Node-RED is to use the node-red command in a command prompt: If you have installed Node-RED as a global npm package, you can use the node-red command:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`node-red`}</code></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b40da4599bc129179cdf1bf2974f3a0/e9beb/nodeRED_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABhElEQVQoz4WS6U7CQBSFeRFlqUXFTpdZSoFOgXaGriq0BYaWRVHf/xW0YjQmLMn5cXKTL/fcpSZBv2lMJDSRkC+h4Fct6Cv2s0pnLfivfpBMwjYJa4qdAprJJJZQeK16dY3VNdbQeUPndf3PH1VNG84RWwGaSShoGpOmUQVpGPzbVzoHQ0+Y/hrQtBfuzMka8xKzQkLBFXCvVe+Q5SRsBVvn+R3zYpDsrXBnBVvEVgqtZlFo2ulPb7tJC/pn4A/Cy370avpr6AnCCzt5s5N9N9hgVtzg8FT4Hxizwk72vXCnjxaEl1awxbyEroCuUOxZpz892rym0EwbzYGTKfbsvvf0MKhyyiRqm7GEgjPbqmBgp/pooY+XqpMDJ9OGc9XJ9fECuktAswungp7oRS+GK9Rhrg5zQFNttABOBpxMJtHFO+eIrQx3iViBPAE90fU3mBeEl20zvgAb4yVmhUJT5An0bW5wKJNIJvFtN7kw8531KJOoAsy4bcYt6NdVdnjS822/4E9RZax9nTndLwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b40da4599bc129179cdf1bf2974f3a0/e4706/nodeRED_02.avif 230w", "/en/static/0b40da4599bc129179cdf1bf2974f3a0/d1af7/nodeRED_02.avif 460w", "/en/static/0b40da4599bc129179cdf1bf2974f3a0/b6582/nodeRED_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b40da4599bc129179cdf1bf2974f3a0/a0b58/nodeRED_02.webp 230w", "/en/static/0b40da4599bc129179cdf1bf2974f3a0/bc10c/nodeRED_02.webp 460w", "/en/static/0b40da4599bc129179cdf1bf2974f3a0/87ca7/nodeRED_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b40da4599bc129179cdf1bf2974f3a0/81c8e/nodeRED_02.png 230w", "/en/static/0b40da4599bc129179cdf1bf2974f3a0/08a84/nodeRED_02.png 460w", "/en/static/0b40da4599bc129179cdf1bf2974f3a0/e9beb/nodeRED_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b40da4599bc129179cdf1bf2974f3a0/e9beb/nodeRED_02.png",
              "alt": "nodeRED",
              "title": "nodeRED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Point your browser to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:1880/`}</code>{`. You should be looking at the nodeRED interface:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/19d77d9cfbabded08d4369d641e98165/e9beb/nodeRED_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA+klEQVQY042OP0/CQBxA7zsaB1n8Hi0WBAQnNXVQEzcnQoxxMSRCU1lUNGgsgoN/oFJp4DgvcHdec/V+Dk7GYH1560sest3qfqO261YPLs+OO7dr56eLtXLKqSzNd7lxlHIOF07KaCNf2CyurxrprGEWrEzRypYyc80Z6by5smdv72zZOdNC/nD0Nn4nhDDOP6Tk4i98/3UwCPBoPME4DEN0f9Fs1h0hBADoJIIgIIRMKQWtZ9MZenzu3LWvOecA8JkEIROMMaVUKUUpRcPe00u3zf4Rx3EcRdH3IAAwxlC/f9V9cDkXWuvEWEr5I/Y8r9W6UUolPv+OvwAsfZ4uipV96wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19d77d9cfbabded08d4369d641e98165/e4706/nodeRED_03.avif 230w", "/en/static/19d77d9cfbabded08d4369d641e98165/d1af7/nodeRED_03.avif 460w", "/en/static/19d77d9cfbabded08d4369d641e98165/b6582/nodeRED_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/19d77d9cfbabded08d4369d641e98165/a0b58/nodeRED_03.webp 230w", "/en/static/19d77d9cfbabded08d4369d641e98165/bc10c/nodeRED_03.webp 460w", "/en/static/19d77d9cfbabded08d4369d641e98165/87ca7/nodeRED_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19d77d9cfbabded08d4369d641e98165/81c8e/nodeRED_03.png 230w", "/en/static/19d77d9cfbabded08d4369d641e98165/08a84/nodeRED_03.png 460w", "/en/static/19d77d9cfbabded08d4369d641e98165/e9beb/nodeRED_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/19d77d9cfbabded08d4369d641e98165/e9beb/nodeRED_03.png",
              "alt": "nodeRED",
              "title": "nodeRED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "configuring-nodered",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-nodered",
        "aria-label": "configuring nodered permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring nodeRed`}</h2>
    <h3 {...{
      "id": "create-a-static-directory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-a-static-directory",
        "aria-label": "create a static directory permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create a Static directory`}</h3>
    <p>{`TO work with `}<strong parentName="p">{`static files`}</strong>{` directly in `}<strong parentName="p">{`nodeRed`}</strong>{`, open the `}<strong parentName="p">{`settings.js`}</strong>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`\\Users\\<Username>\\.node-red`}</code>{`. Add the following line to the top of the document:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` path `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"path"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Add a folder named `}<strong parentName="p">{`static`}</strong>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.node-red`}</code>{` and change the default staticHTTP path to the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`//httpStatic: '/home/nol/node-red-static/',`}</span>{`
httpStatic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`join`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`__dirname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'static'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/e9beb/nodeRED_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB/klEQVQ4y9WTW28SQRiG97+YSiuHYqEs9EBLW6jWpJf+Qn+A/8Ar74wX3mBNRVu6QIXCntgDy8xjdrCbhTbxwt74JU++ybzvvjPzJauZpoUUAiEE8/kcKaUiXgdhSBjOlDabRcn+1PPw/QDf9wnDMNmPogjNCx3iij+6D4srNpimzXhi4nk+tu0QBKHSomie+OJKr7XB+3cM25eE8ygRpTpAYlkmpmniui6WZalQ5YEHYfeX0a4+fuCmfYE3dZFinnIKzPEdo+EvXMdmPBpiTsaJHPoeQRAshanAS6PPt+sB/ZGNFQicGQo7EAzubIyhye3YoT+yuJ24OKFkGsHI9nG84OGTv3QM2t0Jhgs3DtzYUvWuLekMp3SGHleTGT/uArqWWHgc+DmJsLzoYWDqjanppKe0WnJJT4epQCElIpkBK/0xWNIfveHqKf9S/0Gg+PPbPRUaT1xat9fjnuu/oHzGcr82ehj9Pp8uOnz++h2tsndMaadBtd5kt95it9Fi96hFbf+EnXozoXbQQlf9lNrhqfJXD1oL6k0K+gEva4doz7JlqplNXmXLNPI6jVzcK4qj3DbHBZ3Gps6bfIXtgk4mX+H5xtaCFynWi6ytF9EyxSqH2RJvCzpnxRqv8xVOSns0SvucF6qcxXq1xvlmhXK2xFquzEauzPpj5Mv8Bvm/qo5faVddAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/e4706/nodeRED_04.avif 230w", "/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/d1af7/nodeRED_04.avif 460w", "/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/b6582/nodeRED_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/a0b58/nodeRED_04.webp 230w", "/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/bc10c/nodeRED_04.webp 460w", "/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/87ca7/nodeRED_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/81c8e/nodeRED_04.png 230w", "/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/08a84/nodeRED_04.png 460w", "/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/e9beb/nodeRED_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6b6ed88dd5e0e6440c81b2c07ec1deae/e9beb/nodeRED_04.png",
              "alt": "nodeRed",
              "title": "nodeRed",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Restart nodeRed and test if everything is working, by adding an image file to the newly created `}<strong parentName="p">{`static`}</strong>{` folder (e.g. test.png) and open it via the nodeRed URL on port `}<em parentName="p">{`1880`}</em>{` - the image should be displayed in your browser window:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://127.0.0.1:1880/test.png`}</code></pre></div>
    <p>{`For more information, check out our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/"
      }}>{`Node-RED Guide`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      